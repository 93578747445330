import CustomEmoji from '../components/forms/formComponents/CustomEmoji';

export const stateToHTMLOptions = {
    defaultBlockTag: 'div',
    inlineStyles: {
        BOLD: {
            element: 'b'
        },
        ITALIC: {
            element: 'i'
        },
    },
};

export const defaultToolbar = {
    options: ['inline', 'emoji'],
    inline: {
        options: ['bold', 'italic'],
    },
    emoji: {
        className: 'emoji-toolbar',
        component: CustomEmoji
    }
};

export const descriptionToolbar = {
    ...defaultToolbar,
    options: ['inline', 'emoji', 'list'],
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
};

export const mailToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic'],
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },
    emoji: {
        className: 'emoji-toolbar',
        component: CustomEmoji
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
    }
};

export const uaTranslations = {
    "generic.add": "Додати",
    "generic.cancel": "Скасувати",
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Блокцитування",
    "components.controls.blocktype.code": "Код",
    "components.controls.blocktype.blocktype": "Тип блоку",
    "components.controls.blocktype.normal": "Звичайний",
    "components.controls.colorpicker.colorpicker": "Вибір кольору",
    "components.controls.colorpicker.text": "Текст",
    "components.controls.colorpicker.background": "Виділити",
    "components.controls.embedded.embedded": "Вбудовано",
    "components.controls.embedded.embeddedlink": "Вбудоване посилання",
    "components.controls.embedded.enterlink": "Введіть посилання",
    "components.controls.emoji.emoji": "Смайли",
    "components.controls.fontfamily.fontfamily": "Шрифт",
    "components.controls.fontsize.fontsize": "Розмір шрифту",
    "components.controls.history.history": "Історія",
    "components.controls.history.undo": "Скасувати",
    "components.controls.history.redo": "Повторити",
    "components.controls.image.image": "Зображення",
    "components.controls.image.fileUpload": "Завантаження файлу",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Видаліть файл або натисніть, щоб завантажити",
    "components.controls.inline.bold": "Жирний",
    "components.controls.inline.italic": "Курсив",
    "components.controls.inline.underline": "Підкреслення",
    "components.controls.inline.strikethrough": "Закреслення",
    "components.controls.inline.monospace": "Монопростір",
    "components.controls.inline.superscript": "Надстрочний індекс",
    "components.controls.inline.subscript": "Підпис",
    "components.controls.link.linkTitle": "Назва посилання",
    "components.controls.link.linkTarget": "Ціль посилання",
    "components.controls.link.linkTargetOption": "Відкрити посилання в новому вікні",
    "components.controls.link.link": "Посилання",
    "components.controls.link.unlink": "Від'єднати",
    "components.controls.list.list": "Список",
    "components.controls.list.unordered": "Невпорядковано",
    "components.controls.list.ordered": "Замовлено",
    "components.controls.list.indent": "Відступ",
    "components.controls.list.outdent": "Outdent",
    "components.controls.remove.remove": "Видалити",
    "components.controls.textalign.textalign": "Вирівнювання тексту",
    "components.controls.textalign.left": "Вліво",
    "components.controls.textalign.center": "Центр",
    "components.controls.textalign.right": "Праворуч",
    "components.controls.textalign.justify": "Обґрунтувати"
  };

  export const etTranslations = {
    "generic.add": "Lisa",
    "generic.cancel": "Tühista",
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Blockquote",
    "components.controls.blocktype.code": "Kood",
    "components.controls.blocktype.blocktype": "Ploki tüüp",
    "components.controls.blocktype.normal": "Tavaline",
    "components.controls.colorpicker.colorpicker": "Värvivalija",
    "components.controls.colorpicker.text": "Tekst",
    "components.controls.colorpicker.background": "Esiletõstmine",
    "components.controls.embedded.embedded": "Embedded",
    "components.controls.embedded.embeddedlink": "Manustatud link",
    "components.controls.embedded.enterlink": "Sisesta link",
    "components.controls.emoji.emoji": "Emotikonid",
    "components.controls.fontfamily.fontfamily": "Font",
    "components.controls.fontsize.fontsize": "Fondi suurus",
    "components.controls.history.history": "Ajalugu",
    "components.controls.history.undo": "Võta tagasi",
    "components.controls.history.redo": "Tee uuesti",
    "components.controls.image.image": "Pilt",
    "components.controls.image.fileUpload": "Faili üleslaadimine",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Loobuge failist või klõpsake üleslaadimiseks",
    "components.controls.inline.bold": "Paks",
    "components.controls.inline.italic": "Kaldkiri",
    "components.controls.inline.underline": "Allakriipsutus",
    "components.controls.inline.strikethrough": "Läbikriipsutus",
    "components.controls.inline.monospace": "Monospace",
    "components.controls.inline.superscript": "Ülaindeks",
    "components.controls.inline.subscript": "Alamindeks",
    "components.controls.link.linkTitle": "Lingi pealkiri",
    "components.controls.link.linkTarget": "Lingi sihtmärk",
    "components.controls.link.linkTargetOption": "Ava link uues aknas",
    "components.controls.link.link": "Link",
    "components.controls.link.unlink": "Tühista link",
    "components.controls.list.list": "Loend",
    "components.controls.list.unordered": "Tellimata",
    "components.controls.list.ordered": "Tellitud",
    "components.controls.list.indent": "Taane",
    "components.controls.list.outdent": "Outdent",
    "components.controls.remove.remove": "Eemalda",
    "components.controls.textalign.textalign": "Teksti joondamine",
    "components.controls.textalign.left": "Vasak",
    "components.controls.textalign.center": "Keskus",
    "components.controls.textalign.right": "Õige",
    "components.controls.textalign.justify": "Põhjenda"
  };

  export const lvTranslations = {
    "generic.add": "Pridėti",
    "generic.cancel": "Atšaukti",
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Blockquote",
    "components.controls.blocktype.code": "Kodas",
    "components.controls.blocktype.blocktype": "Bloko tipas",
    "components.controls.blocktype.normal": "Normalus",
    "components.controls.colorpicker.colorpicker": "Spalvų parinkiklis",
    "components.controls.colorpicker.text": "Tekstas",
    "components.controls.colorpicker.background": "Paryškinti",
    "components.controls.embedded.embedded": "Įterptas",
    "components.controls.embedded.embeddedlink": "Įterpta nuoroda",
    "components.controls.embedded.enterlink": "Įveskite nuorodą",
    "components.controls.emoji.emoji": "Jaustukai",
    "components.controls.fontfamily.fontfamily": "Šriftas",
    "components.controls.fontsize.fontsize": "Šrifto dydis",
    "components.controls.history.history": "Istorija",
    "components.controls.history.undo": "Anuliuoti",
    "components.controls.history.redo": "Atkurti",
    "components.controls.image.image": "Vaizdas",
    "components.controls.image.fileUpload": "Failo įkėlimas",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Išmeskite failą arba spustelėkite, kad įkeltumėte",
    "components.controls.inline.bold": "Paryškintas",
    "components.controls.inline.italic": "Kursyvas",
    "components.controls.inline.underline": "Pabraukimas",
    "components.controls.inline.strikethrough": "Perbraukimas",
    "components.controls.inline.monospace": "Monospace",
    "components.controls.inline.superscript": "Viršutinis indeksas",
    "components.controls.inline.subscript": "Pavadinimas",
    "components.controls.link.linkTitle": "Nuorodos pavadinimas",
    "components.controls.link.linkTarget": "Nuorodos tikslas",
    "components.controls.link.linkTargetOption": "Atidaryti nuorodą naujame lange",
    "components.controls.link.link": "Nuoroda",
    "components.controls.link.unlink": "Atsieti",
    "components.controls.list.list": "Sąrašas",
    "components.controls.list.unordered": "Neužsakyta",
    "components.controls.list.ordered": "Užsakyta",
    "components.controls.list.indent": "Įtrauka",
    "components.controls.list.outdent": "Outdent",
    "components.controls.remove.remove": "Pašalinti",
    "components.controls.textalign.textalign": "Teksto lygiavimas",
    "components.controls.textalign.left": "Kairė",
    "components.controls.textalign.center": "Centras",
    "components.controls.textalign.right": "Teisingai",
    "components.controls.textalign.justify": "Ištaisyti"
  };

  export const ltTranslations = {
    "generic.add": "Pievienot",
    "generic.cancel": "Atcelt",
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Blockquote",
    "components.controls.blocktype.code": "Kods",
    "components.controls.blocktype.blocktype": "Bloka tips",
    "components.controls.blocktype.normal": "Normāls",
    "components.controls.colorpicker.colorpicker": "Krāsu atlasītājs",
    "components.controls.colorpicker.text": "Teksts",
    "components.controls.colorpicker.background": "Izcelt",
    "components.controls.embedded.embedded": "Embedded",
    "components.controls.embedded.embeddedlink": "Iegultā saite",
    "components.controls.embedded.enterlink": "Ievadiet saiti",
    "components.controls.emoji.emoji": "Emocijas",
    "components.controls.fontfamily.fontfamily": "Fonts",
    "components.controls.fontsize.fontsize": "Fonta lielums",
    "components.controls.history.history": "Vēsture",
    "components.controls.history.undo": "Atsaukt",
    "components.controls.history.redo": "Atkārtot",
    "components.controls.image.image": "Attēls",
    "components.controls.image.fileUpload": "Failu augšupielāde",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Nometiet failu vai noklikšķiniet, lai augšupielādētu",
    "components.controls.inline.bold": "Bold",
    "components.controls.inline.italic": "Slīpraksts",
    "components.controls.inline.underline": "Pasvītrojums",
    "components.controls.inline.strikethrough": "Pārsvītrojums",
    "components.controls.inline.monospace": "Monospace",
    "components.controls.inline.superscript": "Virsraksts",
    "components.controls.inline.subscript": "Apakšindekss",
    "components.controls.link.linkTitle": "Saites nosaukums",
    "components.controls.link.linkTarget": "Saites mērķis",
    "components.controls.link.linkTargetOption": "Atvērt saiti jaunā logā",
    "components.controls.link.link": "Saite",
    "components.controls.link.unlink": "Atsaistīt",
    "components.controls.list.list": "Saraksts",
    "components.controls.list.unordered": "Nesakārtots",
    "components.controls.list.ordered": "Pasūtīts",
    "components.controls.list.indent": "Indent",
    "components.controls.list.outdent": "Outdent",
    "components.controls.remove.remove": "Noņemt",
    "components.controls.textalign.textalign": "Teksta līdzināšana",
    "components.controls.textalign.left": "Pa kreisi",
    "components.controls.textalign.center": "Centrs",
    "components.controls.textalign.right": "Pareizi",
    "components.controls.textalign.justify": "Attaisnot"
  };
